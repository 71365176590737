
import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
import {
    mdiArrowUpThin,
    mdiTelevisionClassic,
    mdiTwitter,
    mdiWhatsapp,
} from "@mdi/js";
import { addAnalyticsEvent } from "@/plugins/firebase";

@Component({
    components: {
        BackButton,
    },
    metaInfo() {
        const title = "DStv Subscription Price Update - May 2024";
        const description =
            "DStv subscription prices increased on the 1st of May 2024 but you can keep paying the old prices with the price lock feature.";
        return {
            title,
            meta: [
                {
                    name: "og:title",
                    content: title,
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: "og:description",
                    content: description,
                },
                {
                    name: "twitter:description",
                    content: description,
                },
                {
                    name: "description",
                    content: description,
                },
            ],
        };
    },
})
export default class FaqMay2024PriceUpdate extends Vue {
    tvIcon: string = mdiTelevisionClassic;
    externalIcon: string = mdiTwitter;
    upIcon: string = mdiArrowUpThin;
    whatsAppIcon: string = mdiWhatsapp;

    get whatsappPhone() {
        return process.env.VUE_APP_APP_WHATSAPP_PHONE;
    }

    mounted(): void {
        addAnalyticsEvent("price_lock_banner_clicked_v2");
    }
}
